import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function NotFound() {
  return (
    <Layout title="Page not found!">
      <SEO title="404: Not found" />
      <p>Está pagina no existe</p>
      <p>
        Si queres volver al incio <Link to="/">click aquí</Link>
        .
      </p>
    </Layout>
  );
}
